import React, { memo } from "react"
import { compareProps } from "../../helpers/memoHelpers"

export const EventBody = memo(({ name, description, formattedDate }) => {

    return (
        <>
            <h2>{name}</h2>
            <p>{formattedDate}</p>
            <p>{description.split("\n").map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {item}
                        <br/>
                    </React.Fragment>
            )})}</p>
        </>
    )
}, compareProps)

export default EventBody