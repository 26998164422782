import React, { useEffect, useState, memo, useContext } from "react"
import UserContext from "../../contexts/UserContext"
import { compareProps } from "../../helpers/memoHelpers"
import useWindowDimensions from "../general/useWindowDimensions"
import CollapseEventSignUpTable from "./CollapseEventSignUpTable"
import "./EventSignUpTable.css"
import FullEventSignUpTable from "./FullEventSignUpTable"

export const EventSignUpTable = memo(({ signUps }) => {

    const [nameFilter, setNameFilter] = useState("")
    const [gradeFilter, setGradeFilter] = useState("")
    const [users, setUsers] = useState([])
    const { width } = useWindowDimensions();
    const user = useContext(UserContext)

    useEffect(() => {
        setUsers(Object.keys(signUps).map(key => {
            return signUps[key]
        }))
    }, [signUps])

    return (
        <>
            {users.length === 0 ? <p style={{ textAlign: "center", fontSize: "1.5rem", paddingBottom: "50px" }}>No users have signed up yet</p> : <>
        
                <div className="sign-up-filter-container">
                    <div className="input-group">
                        <input required className="sign-up-name-filter-input" id="sign-up-name-filter" type="text" value={nameFilter} onChange={e => setNameFilter(e.target.value)}/>
                        <span className="bar sign-up-name-filter-bar"></span>
                        <label className="sign-up-name-filter-label" htmlFor="sign-up-name-filter">Filter Name</label>
                    </div>
                    <div className="sign-up-select-grade">
                        <div className="sign-up-select-grade-option" id={`${gradeFilter === "9" && "sign-up-grade-selected"}`} onClick={() => {gradeFilter === "9" ? setGradeFilter("") : setGradeFilter("9")}}>Grade 9</div>
                        <div className="sign-up-select-grade-option" id={`${gradeFilter === "10" && "sign-up-grade-selected"}`} onClick={() => {gradeFilter === "10" ? setGradeFilter("") : setGradeFilter("10")}}>Grade 10</div>
                        <div className="sign-up-select-grade-option" id={`${gradeFilter === "11" && "sign-up-grade-selected"}`} onClick={() => {gradeFilter === "11" ? setGradeFilter("") : setGradeFilter("11")}}>Grade 11</div>
                        <div className="sign-up-select-grade-option" id={`${gradeFilter === "12" && "sign-up-grade-selected"}`} onClick={() => {gradeFilter === "12" ? setGradeFilter("") : setGradeFilter("12")}}>Grade 12</div>
                        <div className="sign-up-select-grade-option" id={`${gradeFilter === "staff" && "sign-up-grade-selected"}`} onClick={() => {gradeFilter === "staff" ? setGradeFilter("") : setGradeFilter("staff")}}>Staff</div>
                    </div>
                </div>
                
                <>
                    {(width < 1025 && user.exec) ?
                        <CollapseEventSignUpTable users={users} nameFilter={nameFilter} gradeFilter={gradeFilter}/>
                        : 
                        <FullEventSignUpTable users={users} nameFilter={nameFilter} gradeFilter={gradeFilter}/>
                    }
                </>
                
            </>}
        </>
    )
}, compareProps)

export default EventSignUpTable