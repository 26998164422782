import React, { useState } from 'react'
import WebsiteSettingsContext from '../contexts/WebsiteSettingsContext'
import LoadingScreen from '../components/general/LoadingScreen';
import GradClass from '../components/website-settings/GradClass';

export const WebsiteSettings = () => {

    const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  return (
    <WebsiteSettingsContext.Provider value={{ showLoadingScreen, setShowLoadingScreen }}>
        <LoadingScreen show={showLoadingScreen}/>
        
        <h1 style={{ textAlign: "center" }}>Website Settings</h1>
        <div className="settings-body">
            <GradClass/>
        </div>
    </WebsiteSettingsContext.Provider>
  )
}

export default WebsiteSettings