import { auth, provider } from "../firebase"
import { signInWithPopup } from "@firebase/auth"
import "./FrontPage.css"

export const FrontPage = () => {

    const signIn = async () => {
        await signInWithPopup(auth, provider)
    }

    return (
        <div className="front-page">
            <img 
                className="front-page-media"
                src="https://firebasestorage.googleapis.com/v0/b/elcastudentgovernment.appspot.com/o/media%2Felca-front.jpeg?alt=media&token=a36e298e-5d0b-4a56-b002-ba0d9d27b177"
                alt=""
            />
            <div className="video-text">
                <h1 className="front-page-welcome-text">ELCA Student Government</h1>
                <p className="front-page-welcome-desc">All things SGA, all in one place.</p>
                <button className="btn open-login" onClick={signIn}>Sign In</button>
            </div>
        </div>  
    )
}

export default FrontPage;