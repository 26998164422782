import { useContext, useEffect, useState } from "react"
import UserContext from "../../contexts/UserContext"

export const SchoolwideEventsStatus = ({ events }) => {

    const [totalSchoolwideEvents, setTotalSchoolwideEvents] = useState(0)
    const [schoolwideEventsAttended, setSchoolwideEventsAttended] = useState(0)
    const user = useContext(UserContext)

    useEffect(() => {
        let totalEvents = 0;
        let attendedEvents = 0;

        events.filter(event => event.eventType === "other" && event.takeAttendance).forEach(event => {
            switch (event.attendance[user.uid].status) {
                case "present":
                    totalEvents++;
                    attendedEvents++;
                    break;
                case "absent":
                    totalEvents++;
                    break;
                default:
                    break;
            }
        })

        setTotalSchoolwideEvents(totalEvents)
        setSchoolwideEventsAttended(attendedEvents)
    }, [events, user.uid])

    return (
        <>
        {totalSchoolwideEvents !== 0 &&         
            <p className="attendance-info-text">You have attended {schoolwideEventsAttended}/{totalSchoolwideEvents} schoolwide events</p>
        }
        </>
    )
}

export default SchoolwideEventsStatus