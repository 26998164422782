import { useContext, useEffect, useState } from "react"
import UserContext from "../../contexts/UserContext"

export const ServiceProjectStatus = ({ events }) => {

    const [totalServiceProjects, setTotalServiceProjects] = useState(0)
    const [serviceProjectsAttended, setServiceProjectsAttended] = useState(0)
    const user = useContext(UserContext)

    useEffect(() => {
        let totalEvents = 0;
        let attendedEvents = 0;

        events.filter(event => event.eventType === "other" && event.takeAttendance).forEach(event => {
            switch (event.attendance[user.uid].status) {
                case "present":
                    totalEvents++;
                    attendedEvents++;
                    break;
                case "absent":
                    totalEvents++;
                    break;
                default:
                    break;
            }
        })

        setTotalServiceProjects(totalEvents)
        setServiceProjectsAttended(attendedEvents)
    }, [events, user.uid])

    return (
        <>
        {totalServiceProjects !== 0 &&
            <p className="attendance-info-text">You have attended {serviceProjectsAttended}/{totalServiceProjects} Service Projects. {2 - serviceProjectsAttended > 0 ? `You only need to attend ${2 - serviceProjectsAttended} more service project(s) this semester.` : "You have attended all the service projects required, feel free to attend more!"}</p>
        }
        </>
    )
}

export default ServiceProjectStatus