import { updateDoc, doc } from "@firebase/firestore"
import { useState, memo } from "react"
import { db } from "../../firebase"
import _ from "lodash"
import "./TableRow.css"

export const TableRow = memo(({ id, user }) => {
    
    const [status, setStatus] = useState(user.status !== undefined ? user.status : "absent")

    const updateAttendance = async () => {

        const field = 'attendance.' + user.id + '.status'

        if (status === 'absent') {
            setStatus("present")
            await updateDoc(doc(db, 'events', id), {
                [field]: 'present'
            });
        } else if (status === 'excused') {
            setStatus("absent")
            await updateDoc(doc(db, 'events', id), {
                [field]: 'absent'
            });
        } else {
            setStatus("excused")
            await updateDoc(doc(db, 'events', id), {
                [field]: 'excused'
            });
        }

        
    }

    return (
        <div onClick={updateAttendance} className={`event-attendance-table-row  ${status === "present" && 'event-present'} ${status === "excused" && 'event-excused'} ${status === "absent" && 'event-absent'}`}>
            <p className="event-attendance-table-row-text">{user.firstName} {user.lastName}</p>
            <p className="event-attendance-table-row-text">{status[0].toUpperCase() + status.substring(1)}</p>
        </div> 
    )
},  (prevProps, nextProps) => {

    if (!(prevProps.id === nextProps.id && _.isEqual(prevProps.user, nextProps.user))) {
        return false // causes rerender
    }
    return true
})

export default TableRow;