import React, { memo } from 'react'
import { compareProps } from '../../helpers/memoHelpers'
import "./CollapseSignUpTable.css"

export const CollapseEventSignUpTable = memo(({ users, nameFilter, gradeFilter }) => {

  return (
    <div className='collapse-sign-up-table'>
        {users.filter(user => {
            return ((user.firstName + user.lastName).toLowerCase().includes(nameFilter) || nameFilter === "") && ((user.grade) === gradeFilter || gradeFilter === "")
        }).sort((a,b) => {

            let aGrade;
            let bGrade;

            if (a.grade === "staff") {
                aGrade = 13
            } else {
                aGrade = parseInt(a.grade)
            }
            if (b.grade === "staff") {
                bGrade = 13
            } else {
                bGrade = parseInt(b.grade)
            }

            if(aGrade < bGrade) {
                return -1
            } else if (aGrade > bGrade) {
                return 1
            } else {
                if ((a.firstName + a.lastName).toLowerCase() > (b.firstName + b.lastName).toLowerCase()) {
                    return 1
                } else if ((b.firstName + b.lastName).toLowerCase() > (a.firstName + a.lastName).toLowerCase()) {
                    return -1
                } else {
                    return 0
                }
            }

        }).map((user, index) => {
            return (
                <div className="sign-up-info-container">
                    <div className="show-hide-sign-up-info" style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }} >{user.firstName} {user.lastName}, {user.grade !== "staff" ? `Grade ${user.grade}` : "Staff"}{user.officer ? user.admin ? ", Admin" : ", Officer" : ""}</div>
                    
                        <>
                            <div className="separating-line admin-separator"></div>
                            <p>{user.email}</p>
                            <p>{`${user.phone && user.phone !== "+1" ? `${user.phone.substring(2,5)}-${user.phone.substring(5,8)}-${user.phone.substring(8,user.phone.length)}` : "N/A"}`}</p>
                        </>
                    
                </div>
            )
        })}
    </div>
  )
}, compareProps)

export default CollapseEventSignUpTable
