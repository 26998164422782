import React, { useContext } from 'react'
import UserContext from '../../contexts/UserContext'
import "./FullEventSignUpTable.css"

export const FullEventSignUpTable = ({ users, nameFilter, gradeFilter }) => {

    const currentUser = useContext(UserContext)

  return (
    <div className="sign-up-table" style={currentUser.exec ? {gridTemplateColumns: "repeat(4, 1fr)"} : {gridTemplateColumns: "repeat(2, 1fr)"}}>
        <h3 className="sign-up-table-head" style={{ textAlign: "center", backgroundColor: "var(--accent5)"}}>Name</h3>
        <h3 className="sign-up-table-head" style={{ textAlign: "center", backgroundColor: "var(--accent5)"}}>Grade</h3>
        
        {currentUser.exec && <h3 className="sign-up-table-head" style={{ textAlign: "center", backgroundColor: "var(--accent5)"}}>Email</h3>}
        {currentUser.exec && <h3 className="sign-up-table-head" style={{ textAlign: "center", backgroundColor: "var(--accent5)"}}>Phone</h3>}
    
        {users.filter(user => {
            return ((user.firstName + user.lastName).toLowerCase().includes(nameFilter) || nameFilter === "") && ((user.grade) === gradeFilter || gradeFilter === "")
        }).sort((a,b) => {

            let aGrade;
            let bGrade;

            if (a.grade === "staff") {
                aGrade = 13
            } else {
                aGrade = parseInt(a.grade)
            }
            if (b.grade === "staff") {
                bGrade = 13
            } else {
                bGrade = parseInt(b.grade)
            }

            if(aGrade < bGrade) {
                return -1
            } else if (aGrade > bGrade) {
                return 1
            } else {
                if ((a.firstName + a.lastName).toLowerCase() > (b.firstName + b.lastName).toLowerCase()) {
                    return 1
                } else if ((b.firstName + b.lastName).toLowerCase() > (a.firstName + a.lastName).toLowerCase()) {
                    return -1
                } else {
                    return 0
                }
            }

        }).map((user, index) => {
            return (
                <React.Fragment key={index}>
                    <p className="sign-up-row" style={index % 2 === 0 ? {textAlign: "center", backgroundColor: "var(--accent2)"} : {textAlign: "center", backgroundColor: "var(--accent3)"}}>{`${user.firstName} ${user.lastName}`}</p>
                    <p className="sign-up-row" style={index % 2 === 0 ? {textAlign: "center", backgroundColor: "var(--accent2)"} : {textAlign: "center", backgroundColor: "var(--accent3)"}}>{`${user.grade}`}</p>
                    {currentUser.exec && <p className="sign-up-row" style={index % 2 === 0 ? {textAlign: "center", backgroundColor: "var(--accent2)"} : {textAlign: "center", backgroundColor: "var(--accent3)"}}>{`${user.email}`}</p>}
                    {currentUser.exec && <p className="sign-up-row" style={index % 2 === 0 ? {textAlign: "center", backgroundColor: "var(--accent2)"} : {textAlign: "center", backgroundColor: "var(--accent3)"}}>{`${user.phone && user.phone !== "+1" ? `${user.phone.substring(2,5)}-${user.phone.substring(5,8)}-${user.phone.substring(8,user.phone.length)}` : "N/A"}`}</p>}
                </React.Fragment>
            )
        })}
    </div>
  )
}

export default FullEventSignUpTable