import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./LinkPhone.css"

export const LinkPhone = () => {

    const [red, setRed] = useState(100)
    const [increment, setIncrement] = useState(1)

    

    const carouselInfiniteScroll = () => {
        if (red === 255) {
            setIncrement(-1)
        } else if (red === 100) {
            setIncrement(1)
        }
        return setRed(red + increment)
    }

    useEffect(() => {
        const interval = setInterval(() => carouselInfiniteScroll(), 5);
        return () => clearInterval(interval)
    })

  return (
    <div style={{ backgroundColor: `rgb(${red}, 0 ,0)` }} className='link-container'>
        <h2 className='link-phone-title'>You have not linked your phone number, please link your phone number in settings.</h2>
        <button className='btn link-to-settings'>
        <Link to="/settings">
            Go to Settings
        </Link>
        </button>
    </div>
  )
}

export default LinkPhone