import React from 'react'
// import dayjs from 'dayjs';

export const GradClass = () => {

    // const [gradClass, setGradClass] = useState(dayjs().year());

  return (
    <div className='grad-class'>
        <h2>Graduating Class</h2>
        {/* <select value={gradClass}>
            <option>hi</option>
        </select> */}
    </div>
  )
}

export default GradClass;